<template>
    <div>
        <div class="container">
            <div class="nav-wrap"><div class="wrap" @click="() => {$router.push('/')}"><img class="logo" tabindex="0" src="../assets/img/logo.png"><span class="head-title" tabindex="0">供应链云-分销商平台</span></div></div>
            <div class="container-content">
                <router-view></router-view>
            </div>
        </div>
        <cFooter></cFooter>
    </div>
</template>

<script>

    import cFooter from '../component/footer';

    export default {
        name: "passport",
        components: {
            cFooter,
        },
    }
</script>

<style lang="less" scoped>
.wrap {
    max-width: 1200px;
    margin: 0 auto;
    background-color: #fff;
}
.logo {
    width: 240px;
    height: 60px;
    margin-left: 2rem;
}
    .container {
        background-image: url("../assets/img/bg-logo.png");
        background-repeat: no-repeat;
        background-position: 0 0;
        background-size: 100% 100%;
        min-height: 600px;
        .nav-wrap {
            width: 100%;
            background-color: #fff;
            .wrap {
                padding: 14px 0 12px;
                .head-title {
                    margin-left: 1rem;
                    color: #333;
                    font-weight: 700;
                    font-size: 20px;
                    vertical-align: middle;
                }
            }
        }
        .container-content {
            flex: 1;
        }
    }
</style>